:root {
  --day-width: 12.5vw;
}
.container {
  /* display: flex;
  flex-direction: row; */
  min-height: 100vh; /* Minimum height to fill the viewport */
  width: 100vw;
  padding-top: var(--top-pad);
  box-sizing: border-box;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calendar {
  flex: 1; /* Expand to fill available space */
  display: flex;
  flex-direction: column; /* Change to a vertical flex layout */
  align-items: flex-start; /* Align to the top of the container */
  box-sizing: border-box;
}

.title-box {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  /* padding: 10px; */
}
.day-title {
  width: var(--day-width);
  height: fit-content;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "GT America";
  font-weight: 200;
}
.day-number {
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-flex;
  padding: 0px 3px;
  float: right;
  align-items: center;
  justify-content: center;
  width: 3rem;
}

.day-box {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
  align-content: flex-start;
  border-left: 1px solid black;
  border-top: 1px solid black;
  margin-bottom: 10px;
}
.day-box > * {
  justify-content: space-around;
}
.each-day {
  width: var(--day-width);
  height: 152px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 5px;
}
.each-day:hover {
  background-color: #82f74f;
  cursor: pointer;
  transition-duration: var(--trans-dur);
}
.rotation {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: left;
}
.monthSelector {
  width: 100%;
}
.monthInner {
  display: flex;
  flex-direction: row;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: "Zurich";
  font-weight: 600;
  padding-bottom: 1em;
}
.monthInner > * {
  width: calc(100% / 3);
  text-align: center;
}
.btn-access {
  /* padding: 5px 100px; */
  cursor: pointer;
}
.calendarMobile {
  width: calc(100vw - 1rem);
}
.mob-month {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Zurich";
  font-size: 28px;
  padding-bottom: 1rem;
}

.eventItem {
  width: 100%;

  border-bottom: 1px solid black;
}
.eventItem:hover {
  background-color: #82f74f;
}
.linktext {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
}
.linktext:hover {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: var(--mob-pad-top);
    padding-left: 0px;
    padding-right: 0px;
  }
  .calendar {
    padding: 0px;
  }
  .calendarPage {
    width: 100%;
  }
  .column {
    width: 100%;
    padding: 0px;
  }
}

.return-to-today-wrapper {
  margin: 0 auto;
  padding: 1rem;
}
