.bookshop-quote {
  position: relative;
  width: 290px;
  text-align: justify;
  text-justify: inter-word;
  padding-top: 6em;
  padding-bottom: 6em;
  justify-content: space-around;
  font-size: 1.5em;
  line-height: 1em;
  margin-left: calc(50% - 145px);
}

@media only screen and (max-width: 970px) {
  .bookshop-quote {
    height: fit-content;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 6em;
  }
}
