:root {
  --mob-pad-top: calc(75px + 1rem);
  --mob-pad-left: 10px;
  --left-pad: 150px;
  --top-pad: 174px;
  --trans-dur: 0.3s;
}

@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Mono-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Mono-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Mono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Zurich";
  src: url("./fonts/Zurich-Bold-Condensed-BT.ttf") format("truetype");
}
* {
  /* box-sizing: border-box; */
  padding: 0;
  margin: 0;
  cursor: none;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
body {
  background-color: #f6f6f6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw !important;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.terrainlogo {
  width: 500px;
  height: auto;
}
h1 {
  font-family: "Zurich";
  font-size: 4rem;
  line-height: 4rem;
}
h2 {
  font-family: "Zurich";
  font-size: 1rem;
  display: inline;
}
h3 {
  font-family: "Zurich";
  font-size: 2rem;
  line-height: 2rem;
  /* text-align: justify; */
  text-justify: inter-word;
}
h5 {
  font-family: "GT America";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
p {
  font-family: "GT America";
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5rem;
  /* text-align: justify; */
  text-justify: inter-word;
}
a {
  font-family: "GT America";
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  color: black;
  text-decoration: underline;
}
p a:hover {
  border-radius: 15px;
  text-decoration: none;
  background-color: #dadada;
  transition-duration: var(--trans-dur);
}

.fullheight {
  height: 100vh;
}

@media only screen and (max-width: 970px) {
  body {
    padding: 0.5rem;
  }
  h1 {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
  h2 {
    font-size: 1rem;
    display: inline;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    /* text-align: justify; */
  }
  h5 {
    font-family: "GT America";
    font-weight: 400;
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
