.AboutMenuContainer {
  gap: 10px;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 8;
}
.AboutMenu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
}
.NonCurrentTab {
  color: rgba(111, 111, 111, 1);
  font-family: "Zurich";
  font-weight: 600;
  font-stretch: condensed;
  text-decoration: none;
}
.NonCurrentTab:hover {
  color: rgba(0, 0, 0, 1);
}
.CurrentTab {
  color: rgba(0, 0, 0, 1);
  font-family: "Zurich";
  font-weight: 600;
  font-stretch: condensed;
  text-decoration: none;
}
@media only screen and (max-width: 970px) {
  .AboutMenuContainer {
    padding-top: 20px;
    margin-top: -10px;
    width: 100vw;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      #f6f6f6,
      40%,
      #f6f6f6,
      90%,
      rgba(255, 255, 255, 0)
    );
  }
  .AboutMenu {
    /* display: none; */
  }
}
