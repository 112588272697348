.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
  width: 100%;
}
.outer > * {
  width: 50%;
}
.outer {
  gap: 10px;
  display: flex;
  flex-direction: row; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: var(--left-pad);
  padding-top: var(--top-pad);
}
.column {
  display: flex;
  flex-direction: column;
}
.left-col {
  display: flex;
  flex-direction: column;
}
.textbox {
  width: 480px;
  position: relative;
  /* margin-left: calc(50% - 15vw); */
  display: flex;
  flex-direction: row;
}
.fullheight {
  height: 100vh;
}
.rotation {
  writing-mode: vertical-rl; /* Rotate the text vertically */
  white-space: nowrap; /* Prevent line breaks */
  transform: rotate(180deg);
  text-align: right;
  font-family: "Zurich";
  font-size: 32px;
  line-height: 28px;
}
.text-img {
  display: flex;
  flex-direction: row;
}
.imageCont {
  width: 480px;
  height: auto;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
  margin-top: 50px;
}

@media only screen and (max-width: 970px) {
  .outer {
    flex-direction: column;
    padding-left: 0;
    padding-top: var(--mob-pad-top);
    width: 100%;
  }
  .column {
    width: 100%;
  }
  .textbox {
    margin: 0px;
    width: 100%;
  }
  .left-col {
    width: 100%;
    /* padding-right: 10px; */
  }
  .fullheight {
    height: fit-content;
  }
}
