.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
  width: 100vw;
}
.container > * {
  width: 50vw;
}
.aboutPage {
  gap: 10px;
  display: flex;
  flex-direction: column; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: var(--left-pad);
  padding-top: var(--top-pad);
  padding-bottom: 150px;
}
.aboutPage > * {
  width: 50vw;
}
.AboutSection {
  gap: 10px;
  display: flex;
  padding: 0 0 100px;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: fit-content;
}
.textbox {
  gap: 10px;
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-left: 75%;
}
.imageborder {
  top: 0px;
  left: 0px;
  width: 480px;
  height: 639px;
  display: flex;
  position: absolute;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
}
.textbox > h5 {
  line-height: 100px;
}
.fundingText a {
  font-size: var(--font-size);
}
.logo-insitu {
  font-family: "Zurich";
  text-decoration: none;
  user-select: none;
  font-size: var(--font-size);
}
.logo-insitu:hover {
  text-decoration: none;
  color: black;
  cursor: text;
}
@media only screen and (max-width: 970px) {
  .aboutPage {
    align-items: flex-start;
    flex-shrink: 0;
    padding-left: 0;
    padding-top: var(--mob-pad-top);
  }

  .AboutSection {
    padding-top: var(--mob-pad-top);
    min-height: fit-content;
  }
  .aboutPage > * {
    width: 100%;
  }
  .textbox {
    margin: 0px;
    width: 100%;
    padding-top: 60px;
  }
}
