.container {
  display: flex;
  flex-direction: row;
}
a {
  text-decoration: underline;
}
.outer {
  align-items: flex-start;
  flex-shrink: 0;
  /* padding-left: var(--left-pad); */
  padding-top: var(--top-pad);
  width: 100%;
  padding-left: var(--left-pad);
}
.fullheight {
  min-height: 100vh;
  height: fit-content;
  width: calc(50vw - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-shrink: 0;
}
.fullheight2 {
  min-height: 100vh;
  height: fit-content;
  width: calc(50vw - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-shrink: 0;
}

.bookshop-quote {
  position: relative;
  width: 290px;
  text-align: justify;
  text-justify: inter-word;
  padding-top: 6em;
  padding-bottom: 6em;
  justify-content: space-around;
  font-size: 1.5em;
  line-height: 1em;
  margin-left: calc(50% - 145px);
}

.textbox {
  position: relative;
  max-width: 30vw;
  margin-left: calc(50% - 15vw);
  align-items: flex-start;
  flex-shrink: 0;
}
@media only screen and (max-width: 970px) {
  .outer {
    padding: 0;
    padding-top: var(--mob-pad-top);
  }
  .container {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
  .textbox {
    margin: 0px;
    padding: 0;
  }
  .textbox p {
    width: calc(100vw - 2rem);
  }
  .fullheight {
    height: fit-content;
    width: 100%;
    flex-direction: column;
  }
  .fullheight2 {
    height: fit-content;
    width: 100%;
    flex-direction: column-reverse;
  }
}
