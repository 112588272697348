:root {
  --footer-font: 10px;
}
.footer {
  /* position: absolute; */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  text-align: right;
  margin-top: auto;
  padding-top: 4rem;
  height: fit-content;
  flex-shrink: 0;
}
.footer-r {
  font-family: "Zurich";
  text-align: right;
  font-size: 7em;
  line-height: 1em;
}
.footer-section {
  display: flex;
  flex-direction: column;
  width: 50vw;
  /* padding: 10px; */
}
.footer p {
  font-size: 1em;
  line-height: 1.5em;
  font-family: "GT America";
  font-weight: 200;
  text-align: right;
}
.footer a {
  font-size: 1em;
  line-height: 1.5em;
  font-family: "GT America";
  font-weight: 200;
  text-align: right;
}
.footer-l {
  width: 100%;
}

@media only screen and (max-width: 970px) {
  .footer {
    height: fit-content;
    /* padding: 5px; */
  }
  .footer-section {
    font-size: 0.8em;
    /* padding-bottom: 10px; */
    width: 100%;
  }
  /* .footer-r {
    font-size: 45px;
  } */
}
