.imageCont {
  position: relative;
  /* margin-left: calc(50% - 15vw); */
  width: 480px;
  height: auto;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 4rem;
  /* margin-top: 50px; */
  cursor: pointer;
}
.imageStyle {
  width: 100%;
  height: auto;
  display: block;
}

.imageCont:hover {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.5);
}
.zoomedCont {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(246, 246, 246, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
}
.zoomedStyle {
  height: 95vh;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 970px) {
  .imageCont {
    width: 100%;
    height: auto;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
    margin-top: 1.8rem;
    margin-bottom: 3.2rem;
  }
  .zoomedStyle {
    height: 70vh;
  }
}
