.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
}
.image-container {
  position: fixed;
  padding-left: calc(50vw - 100px);
  padding-top: 23vh;
  z-index: -10;
  width: 200px;
  height: auto;
}
.image-container > * {
  width: 200px;
  opacity: 0.7;
  box-shadow: 2px 2px 15px black;
}

.outer {
  gap: 10px;
  display: flex;
  flex-direction: row; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
  /* padding-left: var(--left-pad); */
  padding-top: var(--top-pad);
  padding-bottom: 150px;
  position: relative;
  z-index: 10;
}
.column {
  display: flex;
  flex-direction: column;
  width: 50vw;
  /* margin: 0px 10px; */
  /* padding: 15px; */
}
.column p {
  text-align: right;
  font-size: 1rem;
  line-height: 1.5rem;
}

.fullheight {
  min-height: 100vh;
}
.left-col {
  /* width: calc(50vw - 150px); */
  width: 800px;
}
.manifesto {
  font-family: "Zurich";
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-shadow: 3px 3px 5px #f6f6f6;
}
.textbox {
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  flex-shrink: 0;
  text-shadow: 3px 3px 8px #f6f6f6;
}
.imageboarder {
  top: 0px;
  left: 0px;
  width: 480px;
  height: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.manifestoImage {
  width: 480px;
  height: auto;
  padding-top: 20vh;
}

@media only screen and (max-width: 970px) {
  .outer {
    /* padding-left: var(--mob-pad-left); */
    padding-top: var(--mob-pad-top);
    width: calc(100vw - 30px);
  }
  .left-col {
    width: 60vw;
    font-size: 1rem;
  }
  .manifesto {
    font-size: 2rem;
    line-height: 2rem;
  }
  .column {
    padding: 0px;
  }
  .column p {
    font-size: 1rem;
    line-height: 2.1rem;
  }
  .image-container {
    /* padding-left: calc(50vw - 75px); */
  }
  .fullheight {
    height: fit-content;
  }
}
