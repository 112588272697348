.container {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  gap: 2.4rem;
  text-align: center;
  overflow: visible;
  width: calc(100vw - 2rem);
  /* height: 100vh; */
}

.terrainlogo {
  padding: 2rem;
  width: 100%;
  height: auto;
}

.logotext {
  width: 100%;
  /* height: 100%; */
  /* position: fixed;
  z-index: -2; */
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}
.ent-wrap {
  /* position: absolute; */
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 100%;
}

.buttonStyle {
  display: inline-block;
  border: 1px solid black;
  border-radius: 24px;
  width: fit-content;
  padding: 0.1rem 0.25rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
  background-color: #f6f6f6;
  text-decoration: none;

  animation: 2s fadeIn ease-in-out;
}
.buttonStyle:hover {
  background-color: #82f74f;
  transition-duration: var(--trans-dur);
  transition-timing-function: ease-in-out;
}

.buttonStyle:active {
  background-color: #0f45c9;
  border-color: transparent;
  color: white;
  transition-duration: var(--trans-dur);
}

.hoveredButton {
  background-color: #82f74f;
}
.hover-img {
  position: absolute;
  top: 0vh;
  left: 0vw;
  z-index: 10;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 970px) {
  .container {
    margin-top: 35vh;
    gap: 4.8rem;
  }
  /* .terrainlogo {
    margin-top: 45vh;
  } */
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
