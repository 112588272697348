.resourceMenuItem {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  width: calc(25vw - 10px);
  height: fit-content;
  font-size: 1rem;
  line-height: 1.8rem;
  font-family: "GT America";
  font-weight: 200;
  color: black;
}

.list-cat {
  align-self: stretch;
  padding: 0.1rem 0.25rem;
  font-size: 1rem;
  line-height: 1.8rem;
  position: relative;
  background-color: #f6f6f6;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 1);
  text-transform: uppercase;
}
.list-cat:hover {
  box-shadow: 2px 2px 4px #00000040;
  background-color: #82f74f;
  /* background-image: linear-gradient(90deg, #82f74f, #f6f6f6); */
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 1);
}

.resourceMenuItem .text-wrapper {
  line-height: normal;
  position: absolute;
}
.counter {
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  /* border: 1px solid black; */
  width: 30px;
  padding: 0px 10px;
  text-align: center;
  border-radius: 10px;
  float: right;
}
.activeItem {
  background-color: #82f74f; /* Your desired active item background color */
  box-shadow: 2px 2px 4px #00000040;
}
/* 
.resourceMenuItem .rectangle-2 {
  height: 24px;
  position: absolute;
  top: 5px;
  width: 37px;
} */
@media only screen and (max-width: 970px) {
  .resourceMenuItem {
    width: 100%;
    padding: 2px 3px;
  }
  .list-cat {
    margin-right: 0px;
    margin-bottom: 1px;
  }
}
