.outer {
  gap: 10px;
  display: flex;
  flex-direction: column; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: var(--left-pad);
  padding-top: var(--top-pad);
  padding-bottom: 150px;
}
.outer > * {
  width: 50vw;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
  width: 100%;
}

.ServicesSection {
  gap: 10px;
  display: flex;
  padding: 0 0 100px;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: fit-content;
}

.textbox {
  gap: 10px;
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-left: 75%;
}

.textbox > h5 {
  line-height: 100px;
}

@media only screen and (max-width: 970px) {
  .outer {
    width: 100%;
    align-items: flex-start;
    flex-shrink: 0;
    padding-left: 0;
    padding-top: var(--mob-pad-top);
  }

  /* .ServicesSection {
    padding-top: var(--mob-pad-top);
  }
  .ServicesContent > * {
    width: calc(100vw - 2rem);
  }
  .textbox {
    margin: 0px;
    width: calc(100vw - 2rem);
    padding-top: 60px;
  }
    .aboutPage {
    align-items: flex-start;
    flex-shrink: 0;
    padding-left: 0;
    padding-top: var(--mob-pad-top);
  } */

  .ServicesSection {
    padding-top: var(--mob-pad-top);
    min-height: fit-content;
    width: calc(100vw - 2rem);
  }

  .textbox {
    margin-left: 0px;
    width: 100%;
    padding-top: 60px;
  }
}
