.garden-object {
  box-sizing: border-box;
  background-color: #f6f6f6;
  height: fit-content;
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 30px;
  border-radius: 15px;
  border: 1px solid black;
  width: calc(100% - 6px);
}
.garden-object:hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}
.garden-object .row {
  height: fit-content;
  width: 100%;
}

.linktext {
  color: #000000;
  text-decoration: none;
}
.linktext:hover {
  text-decoration: none;
}
