.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: visible;
  min-height: 100vh;
}

.homepage {
  width: 80vw;
  display: flex;
  flex-direction: column; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
}

.terrainlogo {
  width: 590px;
  height: auto;
}

.para-text {
  display: inline-block;
  font-family: "GT America";
  font-weight: 200;
  font-size: 2.4rem;
  line-height: 3rem;
  flex-shrink: 0;
}
.buttonStyle {
  display: inline-block;
  border: 1px solid black;
  border-radius: 24px;
  width: fit-content;
  padding: 0rem 0.5rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
  background-color: #f6f6f6;
  text-decoration: none;
}
.buttonStyle:hover {
  background-color: #82f74f;
  transition-duration: var(--trans-dur);
  transition-timing-function: ease-in-out;
  text-decoration: none;
}
.buttonStyle:active {
  background-color: #0f45c9;
  border-color: transparent;
  color: white;
  transition-duration: var(--trans-dur);
  transition-timing-function: ease-in-out;
}

.hoveredButton {
  background-color: #82f74f;
}
.hover-img {
  position: absolute;
  top: 0vh;
  left: 0vw;
  z-index: 10;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 970px) {
  .homepage {
    width: 80vw;
    min-height: 100vh;
    margin: 0px;
  }
  .terrainlogo {
    width: 280px;
    height: auto;
  }
  .para-text {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .buttonStyle {
    font-size: 2rem;
    line-height: 2rem;
  }
  .textcontent {
    width: 90vw;
  }
}
@media only screen and (max-width: 400px) {
  .para-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .buttonStyle {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0rem 0.25rem;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (min-width: 1740px) {
  .para-text {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
  .buttonStyle {
    padding: 0.1rem 0.5rem;
    font-size: 3.2rem;
    line-height: 3.2rem;
    border-radius: 4rem;
  }
}
