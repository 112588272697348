#root {
  display: flex;
  flex-direction: column;
}
#root > * {
  position: relative;
}

.container {
  width: 100%;
  overflow-x: visible;
}
.container::-webkit-scrollbar {
  display: none;
}
