.rotation {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: right;
  width: fit-content;
  font-size: 5.2em;
  line-height: 1em;
  font-family: "Zurich";
}
@media screen and (max-width: 970px) {
  .rotation {
    font-size: 3em;
  }
}
