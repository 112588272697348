.outer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: var(--top-pad);
}
.container {
  background-image: linear-gradient(#f6f6f6, #b0b0b0);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}

.rotation {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: left;
}

.digital-garden {
  box-shadow: 5px 10px 20px #00000080;
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  gap: 0.25rem;
  height: 70vh;
  padding: 1rem;
  width: calc(100vw - 2rem);
}

.gardenMenu {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  overflow-y: scroll;
}

.gardenContent {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}
.gardenContent::-webkit-scrollbar {
  display: none;
}
.drop-down {
  height: fit-content;
  background-color: #f6f6f6;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 1);
  align-self: stretch;
  padding: 0.25rem;
  font-size: 1rem;
  line-height: 1.8rem;
  font-family: "GT America";
  font-weight: 200;
  color: black;
  text-transform: uppercase;
}
.drop-down:hover {
  background-color: #82f74f;
  cursor: pointer;
}
.plus {
  width: 10px;
}
.ddMenu {
  display: flex;
  width: 100%;
}
.ddMenu:first-child {
  width: calc(100% - 14px);
}
@media only screen and (max-width: 970px) {
  .outer {
    padding-top: var(--mob-pad-top);
  }
  .container {
    padding-top: 0;
  }
  .digital-garden {
    flex-direction: column;
    width: calc(100vw - 1rem);
  }
  .gardenOuter {
    width: 100%;
  }
  .gardenContent {
    width: 100%;
    height: 90vh;
  }
}
