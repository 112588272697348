.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
  width: 100vw;
}
.outer > * {
  width: 50vw;
}

.outer {
  gap: 10px;
  display: flex;
  flex-direction: row; /* Change to a vertical flex layout */
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--top-pad);
  padding-left: 10px;
  padding-bottom: 150px;
}
.imageCont {
  position: relative;
  /* margin-left: calc(50% - 15vw); */
  width: 480px;
  height: auto;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
  margin-top: 50px;
  cursor: pointer;
}
.imageStyle {
  width: 100%;
  height: auto;
  display: block;
}
.column {
  width: 50vw;
  display: flex;
  flex-direction: column;
  margin-left: var(--left-pad);
}
.textbox {
  width: 480px;
  align-items: flex-start;
  flex-shrink: 0;
}
.image {
  width: 480px;
  height: auto;
}
.imgCaptions {
  font-family: "GT America";
  font-style: Bold Condensed BT;
  font-weight: 1000;
  font-size: 0.8rem;
  line-height: 1.5rem;
}
.text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 80px;
  font-style: Bold Condensed BT;
  text-align: left;
  font-family: Zurich;
  font-weight: 700;
  line-height: 80px;
  font-stretch: condensed;
  text-decoration: none;
}
.imageborder {
  top: 0px;
  left: 0px;
  width: 480px;
  height: auto;
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
}
@media only screen and (max-width: 768px) {
  .outer {
    flex-direction: column;
    padding-top: var(--mob-pad-top);
  }
  .column {
    margin-left: 0;
  }
  .textbox {
    width: calc(100vw - 20px);
  }
  .outer > * {
    width: 100vw;
  }
  .imageCont {
    width: 90vw;
    margin-left: calc(5vw - 10px);
    height: auto;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.5);
  }
  .zoomedStyle {
    height: 70vh;
  }
}
