.container {
  min-height: 90vh; /* Minimum height to fill the viewport */
  /* width: 100vw; */
  padding-top: var(--top-pad);

  padding-bottom: 150px;
}
.column {
  display: flex;
  flex-direction: column;
}
.rotation {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: left;
}
.archivePage {
  width: calc(100% - 2rem);
}

.filterTag {
  width: 123px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 3px;
}

.archiveHeaders {
  width: calc(100vw - 2rem);

  display: grid;
  grid-template-columns: 5fr 3fr 2fr 2fr 0.5fr;
}
.archiveRow {
  width: calc(100vw - 2rem);
  display: grid;
  grid-template-columns: 5fr 3fr 2fr 2fr 0.5fr;
}
.archiveRow:hover {
  background-color: #0f45c9;
  transition-duration: var(--trans-dur);
}

.archiveTitleText {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold Condensed BT;
  text-align: left;
  font-family: Zurich;
  font-weight: 700;
  line-height: normal;
  font-stretch: condensed;
  text-decoration: none;
}
.archiveTitleYear {
  text-align: right;
}

.linktext {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
.linktext:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

@media only screen and (max-width: 970px) {
  .container {
    padding-top: var(--mob-pad-top);
  }
}
@media only screen and (max-width: 768px) {
  .archiveTitleText {
    font-size: 0.8rem;
  }
  .archiveHeaders {
    grid-template-columns: 5fr 4fr 1fr;
  }
  .archiveRow {
    grid-template-columns: 5fr 4fr 1fr;
    font-size: 0.7rem;
  }
}
