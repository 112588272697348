.faq-outer {
  display: flex;
  flex-direction: column;
}
.q-outer {
  border: 1px solid black;
  border-radius: 10px;
  height: fit-content;
  padding: 0.25rem;
  margin: 5px;
}

.q-outer:hover {
  /* background-image: linear-gradient(90deg, #f6f6f6, #b0b0b0); */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition-duration: var(--trans-dur);
  transition: ease-out;
}

.q-underline {
  /* text-decoration: underline; */
  width: 90%;
  font-size: 12px;
  font-family: "GT America";
  font-weight: 200;
  float: left;
}

.q-answer {
  padding: 1rem;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 480px) {
  .q-outer:hover {
    background-image: none;
    box-shadow: none;
  }
}
