.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; /* Adjust as needed */
  text-align: center; /* Adjust as needed */
  color: #fff;
  /* padding: 20px; */
  height: fit-content;
  background-color: green;
}
.row {
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 25;
  height: 130px;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100vw;
  padding-bottom: 30px;
  padding: 1rem;
}
.headerHidden {
  display: none;
}
.terrainlogo {
  width: 480px;
  height: auto;
  position: relative;
  z-index: 25;
}

.navigation {
  width: 100%;
  display: flex;
  align-items: baseline;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
.menuBtn {
  font-family: "GT America";
  font-weight: 200;
  box-sizing: border-box;
  text-decoration: none;
  background-color: #f6f6f6;
  width: fit-content;
  display: inline-block;
  border: 1px solid black;
  border-radius: 24px;
  width: fit-content;
  margin: 0.25rem;
  padding: 0.2rem 0.25rem;
  font-size: 1.1rem;
  line-height: 1.1rem;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.02);
}
.menuBtn:hover {
  background-color: #82f74f;
  transition-timing-function: ease-out;
  transition-duration: var(--trans-dur);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}
.currentLink {
  box-sizing: border-box;
  text-decoration: none;
  background-color: #f6f6f6;
  width: fit-content;
  margin: 0.25rem;
  padding: 0.2rem 0.25rem;
  font-size: 1.1rem;
  line-height: 1.1rem;
  border: 1px solid transparent;
  border-radius: 24px;
  background-color: #0f45c9;
  color: white;
}
.currentLink:hover {
  color: white;
}
.menucolumn {
  display: flex;
  flex-direction: column;
  width: 15vw;
  text-align: left;
  font-size: 50px;
  line-height: 45px;
}

.burger {
  display: none;
}

.mobile-nav {
  width: 100%;
  height: 100%;
  /* background-color: #f6f6f6; */
  background-image: linear-gradient(90deg, transparent, 5%, #f6f6f6);
  position: fixed;
  padding-top: 110px;
  z-index: 8;
  top: 0vh;
  left: 0vw;
}
.hidden {
  display: none;
}

.sub-wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(246, 246, 246, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  z-index: 100;
  animation: fadeIn 1s linear;
  text-align: right;
  color: black;
  font-family: "GT America";
  font-size: 3.2rem;
  line-height: 3.2rem;
}
.sub-inner {
  width: 50vw;
  height: 12rem;
  background-color: #82f74f;
  position: fixed;
  z-index: 100;
  top: calc(50vh - 6rem);
  left: 25vw;
  box-shadow: 6px 3px 15px rgba(0, 0, 0, 0.574);
  padding: 1rem;
  animation: fadeIn 0.3s linear;
}

.subBtn {
  width: fit-content;
  color: black;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 768px) {
  .sub-inner {
    box-sizing: border-box;
    width: calc(100vw - 2rem);
    height: 12rem;
    margin: 1rem;
    z-index: 100;
    background-color: #82f74f;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    top: calc(50vh - 6rem);
    left: 0;
    padding: 1rem;
  }
}

@media only screen and (max-width: 970px) {
  :root {
    --menu-font: 1rem;
  }
  .header {
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 2rem);
    box-sizing: border-box;
  }
  .terrainlogo {
    width: 280px;
    height: auto;
  }
  .row {
    height: 75px;
    background-image: linear-gradient(#f6f6f6, 79%, rgba(255, 255, 255, 0));
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: space-between;
    padding: 1rem;
  }
  .burger-outer {
    width: fit-content;
    height: fit-content;
    text-align: center;
  }
  .burger {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-family: "GT America";
    font-weight: 200;
    line-height: 0.8rem;
    position: relative;
    height: 50px;
    width: 4rem;
    padding-top: 0.6rem;
    color: black;
    /* position: relative; */
    z-index: 25;
  }
  .navigation {
    display: none;
  }
  .terrainlogo {
    position: fixed;
    width: 280px;
    height: auto;
  }
  .mainmenu {
    margin-bottom: 3px;
  }
  .menucolumn {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 0.5rem;
    font-size: 2rem;
  }
}
