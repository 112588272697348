/* input {
  box-sizing: border-box;
  width: calc(100% - 0.5rem);
  padding: 0.1rem 0.25rem;
  font-size: 1rem;
  line-height: 1.8rem;
  border-radius: 24px;
  height: 1.8rem;
  border: 1px solid #6f6f6f;
  background-color: #f6f6f6;
  font-family: "GT America";
  font-weight: 200;
} */

input {
  background-color: #f6f6f6;
  border: 1px solid #6f6f6f;
  border-radius: 24px;
  box-sizing: border-box;
  font-family: GT America;
  font-size: 1rem;
  font-weight: 200;
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0.1rem 0.5rem;
  width: calc(100% - 0.5rem);
}

.searchBar {
  background: none;
  padding: 1rem;
}

.overlap-group {
  height: 30px;
  position: relative;
}

@media only screen and (max-width: 970px) {
  .searchBar .div-wrapper {
    width: calc(100% - 10px);
  }
  input {
    width: 100%;
  }
}
